import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ModalPopup from '../../elements/Popup';
import MasterService from './../../../service/MasterService';
import BlackListPopup from './BlackListPopup';
import { DEALER_WALLET_ID, Reason_For_Inactivate } from '../../../config/constant';
import { DealerService } from './../../../service';
import { toast } from 'react-toastify';
import ViewHistory from '../../dealerDetail/component/ViewHistory';
import Esigned from '../../dealerDetail/component/Esigned';
import InactiveReasonPopup from './InactiveReasonPopup';
import ChangeStatusPopup from './ChangeStatusPopup';
import secureStorage from '../../../config/encrypt';
import {getStatusByValue} from "../../common/Utils"

import statusChangeImg from "../../../../src/webroot/images/change-status.svg"
import esignicon from "../../../../src/webroot/images/esignicon.png"
import MappedUserPopup from './MappedUserPopup';
class SearchResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dealerListData: props.dealerListData,
            role_access: props.role_access || [],
            loading: false,
            pageloading: false,
            statusClassname: {
                'active': 'nav-item active',
                'inactive': 'nav-item',
                'incomplete': 'nav-item',
                'blacklist': 'nav-item',
                'pending': 'nav-item',
                'reject': 'nav-item',
                'all': 'nav-item'
            },
            filterData: {
                status: 1
            },
            dealerUpdateData: {
            },
            blackListPopup: false,
            blackListUpdateData: {},
            inactiveReasonPopup: false,
            inactiveReasonUpdateData: {},
            currentIndex: null,
            current_org_name: null,
            historyPopup : false,
            eSignPopup : false,
            histoy_dealer_id : 0,
            eSign_dealer_id : 0,
            export_access:false,
            bank_redirect: false,
            changeStatusPopup: false,
            dealer_current_status: 0,
            dealer_current_gcd_for_status_change:"",
            changeStatusPopupInfo: {}
        };
    }

    closeHistoryModel = () => {
        this.setState({ historyPopup: false });
    }

    handleHistory = (id) => {
        this.setState({historyPopup : true});
        this.setState({histoy_dealer_id : id});
    }

    closeEsignModel = () => {
        this.setState({ eSignPopup: false });
    }

    handleEsign = (id) => {
        this.setState({eSignPopup : true});
        this.setState({eSign_dealer_id : id});
    }

    componentDidMount = async () => {
        let self = this
        if (secureStorage.getItem('loginUserInfo')) {
            let loginResponse = secureStorage.getItem('loginUserInfo');
            if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
                let export_access = loginResponse.data.user_data.export_access ? loginResponse.data.user_data.export_access : 0
                if (export_access) {
                    self.setState({ export_access: true })
                }
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            dealerListData: nextProps.dealerListData,
            loading: nextProps.loading,
            pageloading: nextProps.pageloading,
            role_access: nextProps.role_access || [],
        });
    }
    handleStatusClick = (sname, ovalue) => {
        let filterData = this.state.filterData;
        if (sname == 'active') {
            filterData['status'] = 1;
        } else if (sname == 'inactive') {
            filterData['status'] = 2;
        } else if (sname == 'incomplete') {
            filterData['status'] = 3;
        } else if (sname == 'pending') {
            filterData['status'] = 4;
        } else if (sname == 'blacklist') {
            filterData['status'] = 5;
        } else if (sname == 'reject') {
            filterData['status'] = 6;
        } else if (sname == 'all') {
            filterData['status'] = null;
        }
        this.setState({ filterData });
        if (typeof this.props.statusClick === 'function') {
            this.props.statusClick(sname, ovalue);
            this.setState({
                statusClassname: {
                    'active': 'nav-item',
                    'inactive': 'nav-item',
                    'incomplete': 'nav-item',
                    'blacklist': 'nav-item',
                    'pending': 'nav-item',
                    'reject': 'nav-item',
                    'all': 'nav-item',
                    [sname]: 'nav-item active'
                }
            })
        }
    }

    getBasicDetails() {
        return new Promise((resolve, reject) => {
            DealerService.getMasters(["dealer_sku"]).then((response) => {
                if (response.status == 200 && response.data.status == 200) {
                    let responsedata = response.data.data || {};
                    if (responsedata.dealer_sku && responsedata.dealer_sku.length) {
                        let subscM = [];
                        // responsedata.dealer_sku.map(v => {//need to remove
                        //     if (v.sku_type == "subscription") subscM.push(v);
                        // })
                        responsedata.dealer_sku.forEach((v) => {
                            if (v.sku_type === "subscription") {
                              subscM.push(v);
                            }
                        });
                        resolve(subscM);
                    }
                }else{
                    reject(response.data)
                }
            }).catch((error) => {
                reject(error)
            });
        })
    }

    checkBalance = async (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                let balance = await DealerService.checkBalance(params);
                if(balance && balance.data && balance.data.status === 200){
                    if (balance && balance.data && balance.data.data && balance.data.data.closing_balance === 0) {
                        resolve(true)
                    } else {
                        resolve(false);
                    }
                }else{
                    reject(balance.data.message)
                }
            } catch (error) {
                reject(error)
            }
        })
    }

    fetchEditData = (dealer_id_hash) => {
        return new Promise((resolve, reject) => {
            DealerService.getSkusDetails(dealer_id_hash).then((response) => {
                if (response.status == 200 && response.data.status == 200) {
                    let editDataAll = response.data.data || [];
                    let [row] = editDataAll.filter(item => item.b_details_sku_id === DEALER_WALLET_ID);
                    this.getBasicDetails().then(subM => {
                        let [master] = subM.filter(item => item.id === DEALER_WALLET_ID);
                        row.b_details_is_active = '0';
                        row.master = master;
                        resolve(row);
                    }).catch(error => reject(error));
                }
                else {
                    reject(response.data)
                }
            }).catch(error => reject(error));
        })
    }

    handleChange = (idx, dealer_id_hash, current_status, dealer_id) => async ({ target }) => {
        let isChecked = target.checked;
        if (parseInt(current_status) === 1) {
            this.handleInactive(idx, dealer_id_hash, current_status, target)
        } else {
            let [dealer_data] = this.state.dealerListData.filter(item => item.dealer_id_hash === dealer_id_hash)
            if (dealer_data.skuidstr !== null) {
                let sku_ids_arr = dealer_data.skuidstr.split(',');
                if (sku_ids_arr.some(item => parseInt(item) === DEALER_WALLET_ID)) {
                    this.fetchEditData(dealer_id_hash).then(async resp => {
                        this.checkBalance({ dealer_id: dealer_id_hash, source: "GCLOUD" }).then(async balance => {
                            if (balance) {
                                let dealer_data_wallet = {
                                    dealer_id: dealer_data.id,
                                    dealer_name: dealer_data.organization,
                                    dealer_contact: dealer_data.dealership_contact,
                                    is_subscribed: '0',
                                }
                                if (parseInt(current_status) === 1) {
                                    DealerService.saveSubscriptions(resp, dealer_id_hash);
                                    DealerService.saveWalletHistory(dealer_data_wallet);
                                }
                                await this.changeDealerStatus(idx, target, isChecked, current_status, dealer_id_hash);
                            } else {
                                toast.error(`Please make sure the Dealer Wallet balance is Rp 0 !`)
                            }
                        })
                    })
                } else {
                    await this.changeDealerStatus(idx, target, isChecked, current_status, dealer_id_hash);
                }
            } else {
                await this.changeDealerStatus(idx, target, isChecked, current_status, dealer_id_hash);
            }
        }
    }

    handleStatusChange = async (idx, dealer_id_hash, current_status, checked, target, inactive_reason, inactive_reason_comment) => {
        let isChecked = checked;
        let [dealer_data] = this.state.dealerListData.filter(item => item.dealer_id_hash === dealer_id_hash)
        if (dealer_data.skuidstr !== null) {
            let sku_ids_arr = dealer_data.skuidstr.split(',');
            if (sku_ids_arr.some(item => parseInt(item) === DEALER_WALLET_ID)) {
                this.fetchEditData(dealer_id_hash).then(async resp => {
                    this.checkBalance({ dealer_id: dealer_id_hash, source: "GCLOUD" }).then(async balance => {
                        if (balance) {                            
                            let dealer_data_wallet = {
                                dealer_id: dealer_data.id,
                                dealer_name: dealer_data.organization,
                                dealer_contact: dealer_data.dealership_contact,
                                is_subscribed: '0',
                            }
                            if(parseInt(current_status) === 1){
                                DealerService.saveSubscriptions(resp, dealer_id_hash);
                                DealerService.saveWalletHistory(dealer_data_wallet);
                            }
                           await this.changeDealerStatus(idx, target, isChecked, current_status, dealer_id_hash, inactive_reason, inactive_reason_comment);
                        } else {
                            toast.error(`Please make sure the Dealer Wallet balance is Rp 0 !`)
                        }
                    })
                })
            } else {
                await this.changeDealerStatus(idx, target, isChecked, current_status, dealer_id_hash, inactive_reason, inactive_reason_comment);
            }
        } else {
            await this.changeDealerStatus(idx, target,isChecked, current_status, dealer_id_hash, inactive_reason, inactive_reason_comment);
        }
    }

    handleStatusChangePopup = (idx, dealer_id_hash, status, gcd, org) => async ({ target }) => {
        let temp = { ...this.state.changeStatusPopupInfo };
        temp.dealer_current_status = status;
        temp.dealer_id_hash = dealer_id_hash;
        temp.target = target;
        temp.org_name = org;
        temp.gcd = gcd;
        temp.idx = idx;
        this.setState({changeStatusPopup:true,dealer_current_status: status,currentIndex: idx,current_org_name: org ,changeStatusPopupInfo:temp });
    }
    closehandleStatusChangePopup = () => {
        this.setState({ changeStatusPopup: false });
    }

    handleStatusChangeAction = async (status) => {
        const {dealer_id_hash, dealer_current_status} = this.state.changeStatusPopupInfo;
        const params = {dealer_id_hash, current_status: dealer_current_status, status: status.toString()}
        this.setState({ loading: true })
        const response = await MasterService.post('dealer/dealer/status_change', { ...params });
        
        let thisObj = this;
        if (response.data.status == 200) {
            thisObj.setState({ loading: false });
        } else {
            this.setState({ loading: false })
            toast.error(response.data.message)
        }
        this.setState({ loading: false })
        
        const bucketname = getStatusByValue(dealer_current_status);
        this.props.statusClick(bucketname.toLowerCase())
    }

    handleBlackList = (idx, dealer_id_hash, status) => async ({ target }) => {
        let tempBlackListUpdateData = { ...this.state.blackListUpdateData };
        tempBlackListUpdateData.status = status;
        tempBlackListUpdateData.dealer_id_hash = dealer_id_hash;
        let org_name = this.state.dealerListData[idx] && this.state.dealerListData[idx].organization
        this.setState({ blackListPopup: true, blackListUpdateData: tempBlackListUpdateData, currentIndex: idx, current_org_name: org_name }, () => { });
    }

    closeModal = () => {
        this.setState({ blackListPopup: false });
    }

    handleInactive = async (idx, dealer_id_hash, status, target) => {
        let tempInactiveReasonUpdateData = { ...this.state.inactiveReasonUpdateData };
        tempInactiveReasonUpdateData.status = status;
        tempInactiveReasonUpdateData.dealer_id_hash = dealer_id_hash;
        tempInactiveReasonUpdateData.checked = target.checked;
        tempInactiveReasonUpdateData.target = target;
        let org_name = this.state.dealerListData[idx] && this.state.dealerListData[idx].organization
        this.setState({ inactiveReasonPopup: true, inactiveReasonUpdateData: tempInactiveReasonUpdateData, currentIndex: idx, current_org_name: org_name }, () => { });
    }

    closeInactiveReasonModal = () => {
        this.setState({ inactiveReasonPopup: false });
    }

    updateDealerListData = (updatedObj, index) => {
        let dealerListTempData = [...this.state.dealerListData];
        dealerListTempData[index].status = updatedObj.status;
        this.setState({ dealerListData: dealerListTempData });
    }
    async changeDealerStatus(idx, target, isChecked, current_status, dealer_id_hash, inactive_reason, inactive_reason_comment) {
        let allow_change_status = true;
        let org = this.state.dealerListData[idx] || {};
        let active = isChecked ? "Active" : "Inactive";
        let r
        if (parseInt(current_status) === 1) {
            r = true
        } else {
            r = window.confirm(this.props.t('Listing.Search_Result.alert_active_inactive', { status: active, dealer_name: org.organization }));
        }
        let dealerUpdateData = this.state.dealerUpdateData;
        dealerUpdateData['inactive_reason'] = inactive_reason?inactive_reason:"";
        dealerUpdateData['inactive_reason_comment'] = inactive_reason_comment?inactive_reason_comment:"";
        if (r == true) {
            if (isChecked) {
                dealerUpdateData['status'] = '1';
                dealerUpdateData['current_status'] = current_status;
                dealerUpdateData['dealer_id_hash'] = dealer_id_hash;

            } else {
                dealerUpdateData['status'] = '2';
                dealerUpdateData['current_status'] = current_status;
                dealerUpdateData['dealer_id_hash'] = dealer_id_hash;
            }
            this.setState({ dealerUpdateData });
            var thisObj = this;
            let dealerListTempData = [...this.state.dealerListData];
            thisObj.setState({loading:true})
            let checkVal = isChecked;
            if (this.state && this.state.dealerUpdateData && this.state.dealerUpdateData.status && this.state.dealerUpdateData.status === '1') {
                let dealerBank = await MasterService.post('/dealer/dealer/all_dealer_banks', { dealer_id_hash: dealer_id_hash });
                if (dealerBank && dealerBank.data && dealerBank.data.status === 200) {
                    let dealerBankData = dealerBank.data.data;
                    if (dealerBankData && dealerBankData.length === 1) {
                        let response = await DealerService.getMasters(["dealer_sku"]);
                        let dealerSkus = await DealerService.getSkusDetails(dealer_id_hash);
                        let dealerSkusData = dealerSkus.data.data;
                        let row = {};
                        if(dealerSkusData && dealerSkusData.length){
                            [row] = dealerSkusData.filter(item => item.b_details_sku_id === DEALER_WALLET_ID);
                        }

                        if (response && response.data && response.data.status === 200) {
                            let skuData = response.data.data.dealer_sku;
                            if (skuData) {
                                let [walletSku] = skuData.filter(item => item.id === DEALER_WALLET_ID);
                                let [dealer_data] = this.state.dealerListData.filter(item => item.dealer_id_hash === dealer_id_hash)
                                if (walletSku && dealer_data) {
                                    if(!row){
                                        row = {};
                                    }
                                    row.master = walletSku;
                                    row.b_details_is_active = '1'
                                    DealerService.saveSubscriptions(row, dealer_id_hash);
                                    let dealer_data_wallet = {
                                        dealer_id: dealer_data.id,
                                        dealer_name: dealer_data.organization,
                                        dealer_contact: dealer_data.dealership_contact,
                                        is_subscribed: '1',
                                    }
                                    DealerService.saveWalletHistory(dealer_data_wallet)
                                }
                            }
                        }
                    } else {
                        allow_change_status = false;
                        thisObj.setState({ loading: false });
                        thisObj.setState({bank_redirect: true});
                        toast.error("Dealer must have a single active Bank Account");
                    }
                }

                let dealerDocs = await DealerService.getDocKycDetails(dealer_id_hash);
                if (dealerDocs && dealerDocs.data && dealerDocs.data.status === 200) {
                    let dealerDocData = dealerDocs.data.data;
                    let fileUploaded = 0;
                    let docVerified = 0;
                    let bpkpmindoc = false;
                    for (const data of dealerDocData) {
                        if(data.kyc_child_id == 14  && data.kyc_master_id == 4 && data.kyc_doc_images && data.kyc_doc_images.length < 2){
                            bpkpmindoc = true
                        }

                        if (data.kyc_doc_images && data.kyc_doc_images.length > 0) {
                            fileUploaded++;
                        }
                        if (data.is_doc_verified === 1) {
                            docVerified++;
                        }
                    }

                    if (bpkpmindoc) {
                        allow_change_status = false;
                        thisObj.setState({ loading: false });
                        toast.error("BPKB + Unit Min 2 doc required")
                    }
                    
                    if (fileUploaded !== docVerified) {
                        allow_change_status = false;
                        thisObj.setState({ loading: false });
                        toast.error("Unverified Documents")
                    }
                }
            }
            if (allow_change_status) {
                const response = await MasterService.post('dealer/dealer/status_change', { ...this.state.dealerUpdateData });
                thisObj.flag = false;
                if (response.data.status == 200) {
                    if (checkVal) {
                        target.parentNode.style.textDecoration = "";
                        dealerListTempData[idx].status = 1;
                    } else {
                        target.parentNode.style.textDecoration = "line-through";
                        dealerListTempData[idx].status = 2;
                    }
                    dealerListTempData[idx]['inactive_reason'] = inactive_reason ? inactive_reason : "";
                    dealerListTempData[idx]['inactive_reason_comment'] = inactive_reason_comment ? inactive_reason_comment : "";
                    thisObj.setState({ dealerListData: dealerListTempData, loading: false });
                } else {
                    thisObj.setState({ loading: false })
                    toast.error(response.data.message)
                }
            }
        }
    }

    render() {
        const { dealerCountStatusDetail } = this.props;
        const { role_access } = this.state;
        return (
            <div className={this.state.loading?"loading result-wrap":"result-wrap"}>
                <div className="card">
                    <div className="pad-15">
                        <div className="row">
                            <div className="col-sm-9">
                                <ul className="list-inline nav-tabs">
                                    <li className={this.state.statusClassname.active} onClick={this.handleStatusClick.bind(this, 'active')}><div>{this.props.t('Listing.Search_Result.Active')}<span>({dealerCountStatusDetail.active})</span></div></li>
                                    <li className={this.state.statusClassname.inactive} onClick={this.handleStatusClick.bind(this, 'inactive')}><div>{this.props.t('Listing.Search_Result.Inactive')}<span>({dealerCountStatusDetail.inactive})</span></div></li>
                                    <li className={this.state.statusClassname.incomplete} onClick={this.handleStatusClick.bind(this, 'incomplete')}><div>{this.props.t('Listing.Search_Result.Incomplete')}<span>({dealerCountStatusDetail.incomplete})</span></div></li>
                                    <li className={this.state.statusClassname.blacklist} onClick={this.handleStatusClick.bind(this, 'blacklist')}><div>{this.props.t('Listing.Search_Result.Blacklist')}<span>({dealerCountStatusDetail.blacklist})</span></div></li>
                                    <li className={this.state.statusClassname.pending} onClick={this.handleStatusClick.bind(this, 'pending')}><div>{this.props.t('Listing.Search_Result.Pending')}<span>({dealerCountStatusDetail.pending})</span></div></li>
                                    <li className={this.state.statusClassname.reject} onClick={this.handleStatusClick.bind(this, 'reject')}><div>{this.props.t('Listing.Search_Result.Rejected')}<span>({dealerCountStatusDetail.reject})</span></div></li>
                                    <li className={this.state.statusClassname.all} onClick={this.handleStatusClick.bind(this, 'all')}><div>{this.props.t('Listing.Search_Result.All')}<span>({dealerCountStatusDetail.all})</span></div></li>
                                </ul>
                            </div>
                            <div className="col-sm-3 text-right pad-t10">
                                {   role_access.includes("export_dealer_data") && <button onClick={()=>this.props.onExportData()} type="button" className="btn btn-link mrg-r30" title={this.props.t('Listing.Search_Result.Export_Data')}>{this.props.t('Listing.Search_Result.Export_Data')}</button>}
                                {
                                    role_access.includes("add_dealer") ? <NavLink to="/dealer/basic-details" className="btn btn-success">{this.props.t('Listing.Search_Result.Add_Dealer')}</NavLink> : ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className="clearfix">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr className='dealer-listing'>
                                        <th className='nowrap'>{this.props.t('Listing.Search_Result.GCD')}</th>
                                        <th className='nowrap'>{this.props.t('Listing.Search_Result.Details')}</th>
                                        <th className='nowrap city-name'>{this.props.t('Listing.Search_Result.City')}</th>
                                        <th className='nowrap'>{this.props.t('Listing.Search_Result.Subscribed_To')}</th>
                                        {this.state.filterData.status === 2 && <th className='inactive_reason'>{this.props.t('Listing.Search_Result.Reason_For_Inactivate')}</th>}
                                        {this.state.filterData.status === 5 && <th className='nowrap'>{this.props.t('Listing.Search_Result.Comment')}</th>}
                                        {this.state.filterData.status === 1 && <th className='nowrap'>{this.props.t('Listing.Search_Result.Mapped_Users')}</th>}
                                        {!(this.state.filterData.status === 5 || this.state.filterData.status === 1) && 
                                            <th>{this.props.t('Listing.Search_Result.Expiring_On')}</th>
                                        }
                                        <th>{this.props.t('Listing.Search_Result.Actions')}</th>
                                        <th>{this.props.t('dealerDetails.Basic_Details_master.view_history')}</th>
                                        {/* <th>Active</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.dealerListData.length == 0 ? <tr><td align="center" colSpan="7"><h6 className="text-center text-danger text-bold">{this.props.t('Listing.Search_Result.No_Record_Found')}</h6></td></tr> : null}
                                    {(this.state.dealerListData.length != 0) && this.state.dealerListData.map((dealerdata, k) =>
                                        
                                        <tr key={k}>
                                            <td className='nowrap'>{dealerdata.gcd_code}
                                                {dealerdata.bm_info.name ?
                                                    <div>
                                                        <div><b>BM Name: </b><span title={dealerdata.bm_info.name}>{dealerdata.bm_info.name.substring(0, 20)}</span>{dealerdata.bm_info.name.length > 20 ? "..." : ''}</div>
                                                        <div><b>BM Mobile: </b>{dealerdata.bm_info.mobile} </div>
                                                    </div>
                                                    : ''}
                                                {dealerdata.bro_info.name ?
                                                    <div>
                                                        <div><b>BRO Name: </b><span title={dealerdata.bro_info.name}>{dealerdata.bro_info.name.substring(0, 20)}</span>{dealerdata.bro_info.name.length > 20 ? "..." : ''}</div>
                                                        <div><b>BRO Mobile: </b>{dealerdata.bro_info.mobile} </div>
                                                    </div>
                                                    : ''}
                                            </td>
                                            <td>
                                                <div>{dealerdata.organization}</div>
                                                <div>{dealerdata.dealership_email}</div>
                                                <div>{dealerdata.dealership_contact}</div>
                                                {/* <div><img src="https://dealeradmin.gaadi.com/admin/public/images/gaadi.png"/></div> */}
                                            </td>
                                            <td>{(dealerdata.cityname) ? dealerdata.cityname : null}</td>
                                            <td>
                                                {
                                                    dealerdata.skunamestr == null ? null :
                                                        dealerdata.skunamestr.split(',').map((skuname, k) =>
                                                            <span className={skuname == 'SFAP' ? "label blue-status mrg-r5" : "label green-status mrg-r5"} key={k}>{skuname}</span>
                                                        )
                                                }
                                            </td>
                                            {this.state.filterData.status === 2 && <td>{dealerdata.inactive_reason == 'others' ? dealerdata.inactive_reason_comment : (Reason_For_Inactivate.findIndex(e=>e.value === dealerdata.inactive_reason) > -1 ? Reason_For_Inactivate.filter(e=>e.value === dealerdata.inactive_reason)[0].label :"")}</td>}
                                            {this.state.filterData.status === 5 && 
                                                <td title={dealerdata.comment}>
                                                    {dealerdata.comment ? dealerdata.comment.substring(0, 30) + (dealerdata.comment.length > 30 ? "..." : '') : ''}
                                                </td>
                                            }
                                            {!(this.state.filterData.status === 5 || this.state.filterData.status === 1) &&
                                                <td>
                                                    {
                                                        dealerdata.validitytostr == null ? null :
                                                            dealerdata.validitytostr.split('~').map((validitystr, key) =>
                                                                <div className="f12 text-light" key={key}>{validitystr}</div>
                                                            )
                                                    }
                                                </td>
                                            }
                                            {this.state.filterData.status === 1 &&
                                                <td>{dealerdata.mapped_user && dealerdata.mapped_user.length ? <MappedUserPopup mapped_user={dealerdata.mapped_user} /> : "" }</td>
                                            }
                                            <td>
                                                <div className="btn-group btn-group-small mrg-r10">
                                                    {
                                                        role_access.includes("view_dealer") ? <Link to={`/dealer/${dealerdata.dealer_id_hash}/basic-details`} className="btn btn-default"><i className="ic-createmode_editedit icons-normal"></i></Link> : ''
                                                    }
                                                    {
                                                        role_access.includes("edit_subscription_details") ? <Link to={`/dealer/${dealerdata.dealer_id_hash}/subscription-details`} className="btn btn-default" title="Edit Subscription"><i className="ic-subscription icons-normal"></i></Link> : ''
                                                    }
                                                    {
                                                        role_access.includes("making_dealer_blacklist")  ? (dealerdata.status == '1' || dealerdata.status == '2') ? <button type="button" className="btn btn-default" onClick={this.handleBlackList(k, dealerdata.dealer_id_hash, '5')} title="Mark as Blacklist"><i className="ic-peson-blacklist icons-normal"></i></button> : (dealerdata.status == '5') ? <button type="button" className="btn btn-default" onClick={this.handleBlackList(k, dealerdata.dealer_id_hash, '1')} title="Mark as Whitelist"><i className="ic-peson-listed icons-normal"></i></button> : null : ''
                                                    }
                                                    {
                                                        /*(role_access.includes("dealer_active_inactive") || role_access.includes("making_dealer_blacklist")) && */((dealerdata.status == '2' || dealerdata.status == "3" || dealerdata.status == '4' || dealerdata.status == '6')) ? <button type="button" className="btn btn-default" onClick={this.handleStatusChangePopup(k, dealerdata.dealer_id_hash, dealerdata.status, dealerdata.gcd_code, dealerdata.organization)} title="Status Change"><img src={statusChangeImg}/></button> :  ''
                                                    }
                                                    {
                                                         role_access.includes("dealer_active_inactive")  && <button type="button" className="btn btn-default" onClick={() => this.handleEsign(dealerdata.id)} title="eSign"> <i><img src={esignicon}/></i></button>
                                                    }
                                                    {
                                                        role_access.includes("dealer_active_inactive")  ? (dealerdata.status == '1' || dealerdata.status == '2' || dealerdata.status == '4') ? <> <label className="switch-btn btn btn-link" htmlFor={"active" + dealerdata.dealer_id_hash} > <input className="switch-btn" id={"active" + dealerdata.dealer_id_hash} value="open" name={"active" + dealerdata.dealer_id_hash} type="checkbox" onClick={this.handleChange(k, dealerdata.dealer_id_hash,dealerdata.status)} checked={(dealerdata.status == '1') ? true : false} /> <div className="slider round"></div> <span className="switch-label"></span> <span className='nowrap'>{this.props.t('Listing.Search_Result.Active')}</span> </label></> : "" :''
                                                    }
                                                </div>

                                            </td>
                                            <td>
                                                <button className="btn nowrap" onClick={() => {this.handleHistory(dealerdata.id)}}>{this.props.t('dealerDetails.Basic_Details_master.view_history')}</button>
                                            </td>
                                        </tr>
                                    )}
                                    {
                                        (this.state.loading) ? <tr><td className="loading" colSpan="6"></td></tr> : null
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                {this.state.blackListPopup == false ? null :
                    <ModalPopup id="black_list" className="modal" title={(parseInt(this.state.blackListUpdateData.status) == 5) ? this.props.t('Listing.Search_Result.Black_List_Dealer') : this.props.t('Listing.Search_Result.White_List_Dealer')} modalClose={this.closeModal}>
                        <BlackListPopup modalClose={this.closeModal} blackListUpdateData={this.state.blackListUpdateData} updateDealerListData={this.updateDealerListData} currentIndex={this.state.currentIndex} current_org_name={this.state.current_org_name} />
                    </ModalPopup>
                }

                {this.state.inactiveReasonPopup == false ? null :
                    <ModalPopup id="inactive_reason" className="modal inactive-popup-outer" title={this.props.t('Listing.Inactivate_Reason_Popup.select_reason_message')} modalClose={this.closeInactiveReasonModal}>
                        <InactiveReasonPopup handleStatusChange={(e, i, q, u, o, v, x)=>this.handleStatusChange(e, i, q, u, o, v, x)} modalClose={this.closeInactiveReasonModal} inactiveReasonUpdateData={this.state.inactiveReasonUpdateData} updateDealerListData={this.updateDealerListData} currentIndex={this.state.currentIndex} current_org_name={this.state.current_org_name} />
                    </ModalPopup>
                }

                {this.state.changeStatusPopup === false ? null :
                    <ModalPopup id="inactive_reason" className="modal inactive-popup-outer" modalClose={this.closehandleStatusChangePopup}>
                        <ChangeStatusPopup dealer_current_status={this.state.dealer_current_status} modalClose={this.closehandleStatusChangePopup} changeStatusPopupInfo={this.state.changeStatusPopupInfo} handleStatusChangeAction={this.handleStatusChangeAction}/>
                    </ModalPopup>
                }

                {this.state.historyPopup === false ? null : <ModalPopup id="view_history" className="modal ViewHistory data-table" title={'View History'} modalClose={this.closeHistoryModel}>
                    { this.state.histoy_dealer_id !== 0 &&  <ViewHistory histoy_dealer_id={this.state.histoy_dealer_id} />}
               </ModalPopup>}

               {this.state.eSignPopup === false ? null : <ModalPopup id="esign" className="modal ViewHistory data-table" title={this.props.t('Listing.Search_Result.esign_verify_dealer')} modalClose={this.closeEsignModel}>
                    { this.state.eSign_dealer_id !== 0 &&  <Esigned eSign_dealer_id={this.state.eSign_dealer_id}  closeEsignModel = {this.closeEsignModel} />}
               </ModalPopup>}
               
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(SearchResult));
